import Body from "./Body/Body"
import Clients from "./Clients/Clients"
import ContactWithUs from "./ContactWithUs/Contact"
import Faq from "./Faq/Faq"
import Fares from "./Fares/Fares"
import ItWorks from "./HowItWorks/HowItWorks"
import MobileApp from "./MobileApp/MobileApp"
import News from "./News/News"
import Opportunites from "./Opportunites/Opportunites"
import Statistics from "./Stats/Stats"



const MainComponents = () => {



    return(
        <>
        <Body />
        <ItWorks />
        <Opportunites />
        <Fares />
        <News />
        <Statistics />
        <MobileApp />
        <Clients />
        <Faq />
        <ContactWithUs />
        </>
        
    )
}


export default MainComponents