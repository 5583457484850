import { Collapse, Container } from "@material-ui/core";
import { useAnimation } from "framer-motion";
import cls from "./faq.module.scss";
// import Image from "next/image";
// import phone_laptop from "../../../../public/images/phone_laptop.svg";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
// import next_icon from "../../../../public/icons/next.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import FaqService from '../../../services/FaqService'

// import { useRouter } from 'next/router'
import { useTranslation } from "react-i18next";
import FaqService from "../../services/FaqService";

const Faq = () => {
  // const { locale } = useRouter()
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState({});
  const [fact, setFact] = useState([]);

  const getFacts = () => {
    FaqService.getFacts().then((res) => {
      setFact(res?.data?.faqs);
    });
  };

  const controls = useAnimation();
  const [inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    getFacts();
  }, []);

  const openFAQ = (id) => {
    setOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div
      style={{ background: "#FFF" }}
      id="FAQ">
      <Container className={cls.container}>
        <div className={cls.wrapper}>
          <div className="header">{t("faq")}</div>
          <div className={cls.faq_questions}>
            {fact?.map((item, index) => (
              <div key={index}>
                <div
                  className={cls.box}
                  onClick={(e) => {
                    openFAQ(item?.id);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    {item?.title[i18n?.language]}
                    <Collapse in={open[item?.id]}>
                      <div>{item?.body[i18n?.language]}</div>
                    </Collapse>
                  </div>
                  {open[item?.id] ? (
                    <ExpandLess sx={{ color: "#323232 !important" }} />
                  ) : (
                    <ExpandMore sx={{ color: "#323232 !important" }} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Faq;
