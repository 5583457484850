import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import cls from "./FaresSlider.module.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper";

import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import iconCheckbox from "../../../assets/images/round_checkbox.svg";

export default function FaresSlider({ data }) {
  const { t } = useTranslation();
  return (
    <div>
      <Swiper
        spaceBetween={40}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="FaresSwiper">
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={cls.card}>
              {item.extra ? (
                <div className={cls.suggestion}>{item.extra}</div>
              ) : (
                ""
              )}
              <div className={cls.card_status}> {item.name.ru} </div>
              <div className={cls.under_title}> {item.description.ru} </div>
              <div className={cls.price}> {item.tariff_price} </div>
              {item?.attributes?.map((strings, index) => (
                <div
                  className={cls.card_items}
                  key={index}>
                  <img
                    src={iconCheckbox}
                    className={cls.checkbox}
                    alt="checkbox"
                    width={20}
                    height={20}
                  />
                  <div> {strings.attribute_name.ru} </div>
                </div>
              ))}
              {item?.disabled_text?.map((dis, index) => (
                <div
                  className={cls.card_items}
                  key={index}>
                  <img
                    src={item.icon_disabled}
                    className={cls.checkbox}
                    alt="checkbox"
                    width={20}
                    height={20}
                  />
                  <div> {dis} </div>
                </div>
              ))}
              <Button>{t("Buy")}</Button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
