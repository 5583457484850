import { Button, Container, Grid, Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
// import supportService from "../../../services/supportService";
import cls from "./contact.module.scss";
import img1 from "../../assets/icons/location.svg"
import img2 from "../../assets/icons/phone.svg"
import img3 from "../../assets/icons/email.svg"
import img4 from "../../assets/icons/time.svg"
import supportService from "../../services/supportService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      sx={{ minWidth: "200px" }}
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

const ContactWithUs = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);

  const data = [
    {
      id: 1,
      icon: img1,
      title: t("address"),
      text: t("soliqServiceAddress"),
      width: "42.67px",
      height: "53.33px",
    },
    {
      id: 2,
      icon: img2,
      title: t("callUs"),
      text: "+998 71 202 32 82",
      extra: "1198",
      width: "48px",
      height: "48px",
    },
    {
      id: 3,
      icon: img3,
      title: "E-mail",
      text: "servis@soliq.uz",
      extra: " ",
      height: "42.67px",
      width: "53.33px",
    },
    {
      id: 4,
      icon: img4,
      title: t("openTimes"),
      text: `${t("monday")}-${t("friday")}`,
      extra: "9:00 - 18:00",
      width: "53.33px",
      height: "53.33px",
    },
  ];

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function handleSubmit(e) {
    e.preventDefault();
    if (
      ref.current.description.value.length > 0 &&
      ref.current.fio.value.length > 0 &&
      ref.current.phone.value.length > 0 &&
      ref.current.topic.value.length > 0
    ) {
      const body = {
        description: ref.current.description.value,
        name: ref.current.fio.value,
        phone: ref.current.phone.value?.replace(/\s/g, ""),
        title: ref.current.topic.value,
      };

      supportService
        .postSupport(body)
        .then((res) => {
          ref.current.description.value = "";
          ref.current.fio.value = "";
          ref.current.phone.value = "+998";
          ref.current.topic.value = "";
          handleClick();
          setDisable(true);
        })
    }
  }
  function handleChange(event) {
    if (event.target.value.length >= 0) {
      setDisable(true);
    }
    if (
      ref.current.description.value.length > 0 &&
      ref.current.fio.value.length > 0 &&
      ref.current.phone.value.length > 0 &&
      ref.current.topic.value.length > 0
    ) {
      setDisable(false);
    }
  }

  return (
    <div id="ContactWithUs" style={{background: '#F3F6FA' , borderBottom:'1px solid #fff'}}>
      <Container>
        <div className={cls.wrapper} id="about">
          <div className={cls.header}>
            <div className="header">{t("ContactUs")}</div>
          </div>
          <div className={cls.block}>
            <div className={cls.left_side}>
              {/* <div className={cls.card_boxes}> */}
              {data.map((item) => (
                <div className={cls.card} key={item.id}>
                  <div className={cls.box} key={item.id}>
                    <img
                      src={item.icon}
                      alt="icon"
                      width={item.width}
                      height={item.height}
                    />
                    <div className={cls.title}> {item.title} </div>
                    <div className={cls.text} style={{ marginBottom: "2px" }}>
                      {" "}
                      {item.text}{" "}
                    </div>
                    <div className={cls.text}> {item.extra} </div>
                  </div>
                </div>
              ))}
              {/* </div> */}
            </div>
            <div className={cls.right_side} item xs={6}>
              <form ref={ref} onSubmit={handleSubmit} onChange={handleChange}>
                <div className={cls.form_box}>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <div>
                          <div className={cls.label}>{t("Name")}</div>

                          <input name="fio" placeholder={t("EnterYourName")} style={{width: '87%'}} />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <div className={cls.label}>{t("Telephone")}</div>

                          <InputMask
                            mask={`+\\9\\9\\8 99 999 99 99`}
                            name="phone"
                            autoComplete="off"
                            placeholder={t("EnterYourNumber")}
                            style={{width: '87%'}}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <div item xs={12}>
                      <div className={cls.label}>{t("Topic")}</div>
                      <input name="topic" placeholder={t("EnterYourTopic")} />
                    </div>

                    <div item xs={12}>
                      <div className={cls.label}>{t("Descriptions")}</div>
                      <textarea name="description" rows={"7"} />
                    </div>
                  </div>
                  <div style={{ cursor: disable ? "not-allowed" : "pointer" }}>
                    <Button
                      type="submit"
                      disabled={disable}
                      style={{
                        border: disable ? "2px solid var(--main-color)" : "",
                      }}>
                      {t("Send")}
                    </Button>
                  </div>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    onClose={handleClose}
                    autoHideDuration={2500}
                    style={{ color: "#fff" }}>
                    <Alert>Muvaffaqiyatli yuborildi</Alert>
                  </Snackbar>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ContactWithUs;
