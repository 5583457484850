import { Container } from "@material-ui/core";

import cls from "./clients.module.scss";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import ClientsSlider from "./ClientsSlider/ClientsSlider";
import { useTranslation } from "react-i18next";
import img3 from "../../assets/images/clients/arca.jpg";
import img2 from "../../assets/images/clients/milliygvardiya.png";
import img1 from "../../assets/images/clients/discover.jpg";
import img4 from "../../assets/images/clients/pochta.jpg";
import img5 from "../../assets/images/clients/posco.png";
import img6 from "../../assets/images/clients/suvtaminot.jpg";
import img7 from "../../assets/images/clients/temiryollar.png";
import img8 from "../../assets/images/clients/uzavtosanoat.png";
import img9 from "../../assets/images/clients/uztrans.jpg";
import img10 from "../../assets/images/clients/toxtaniyozota.jpg";

// const ClientSlider = lazy(() => import("./ClientsSlider/ClientsSlider"))

const logos = [
  {
    id: 1,
    url: img1,
  },
  {
    id: 2,
    url: img1,
  },
  {
    id: 3,
    url: img2,
  },
  {
    id: 4,
    url: img3,
  },
  {
    id: 5,
    url: img4,
  },
  {
    id: 6,
    url: img5,
  },

  {
    id: 7,
    url: img6,
  },
  {
    id: 8,
    url: img7,
  },
  {
    id: 9,
    url: img10,
  },
  {
    id: 10,
    url: img8,
  },
  {
    id: 11,
    url: img9,
  },
];

const Clients = () => {
  const { t } = useTranslation();

  return (
    <div
      id="clients"
      style={{ background: "#F3F6FA" }}>
      <Container>
        <div className={cls.clients_header}>
          <div className="header">{t("Clients")}</div>
          <ClientsSlider data={logos} />
        </div>

        {/* <div className={cls.wrapper}> */}
        {/* <div className={cls.slider}> */}
        {/* </div> */}
        <div className={cls.mobile_version}>
          {logos?.map((item, index) => (
            <div
              className={cls.img_box}
              key={index}>
              <img
                src={item?.url}
                alt="logo"
              />
            </div>
          ))}
        </div>
        {/* </div> */}
      </Container>
    </div>
  );
};

export default Clients;
