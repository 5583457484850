import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function GoogleAnalytics() {
  const location = useLocation();
  const gtag = window.gtag;

  useEffect(() => {
    if (typeof gtag === "function") {
      gtag("config", "UA-87269926-3", {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  }, [gtag, location]);

  return null;
}

export default GoogleAnalytics;
