import { Container } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import FaqService from "../../services/FaqService";

import cls from "./News.module.scss";
import NewsSlider from "./NewsSlider";
import { sendMetrik } from "../../utils/sendMetrik";

const News = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [news, setNews] = useState([]);
  // const router = useRouter();
  // const { locale } = router;

  const getNews = () => {
    FaqService.getNews().then((res) => {
      setNews(res?.data?.news);
    });
  };

  useEffect(() => getNews(), []);

  useEffect(() => {
    controls.start("visible");
  }, [controls, inView]);

  const container = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <div id="news">
      <Container style={{ overflow: "hidden" }}>
        <div
          className={cls.wrapper}
          ref={ref}
          id="about">
          <div className={cls.news_header}>
            <motion.div
              className="header"
              variants={itemVariant}
              transition={{
                delay: 0.4,
                duration: 0.5,
              }}
              exit="hidden">
              {t("news")}
            </motion.div>
            <div
              className={cls.watch_all}
              onClick={() => {
                navigate("/news");
              }}>
              {" "}
              <span>{t("SeeAll")}</span>
            </div>
          </div>
          <motion.div
            variants={container}
            initial="hidden"
            animate={controls}
            ref={ref}
            className={cls.cards_box}>
            <div className={cls.news_cards}>
              {news?.slice(0, 3).map((item) => (
                <div
                  key={item.id}
                  className={cls.card}>
                  <div
                    onClick={() => {
                      sendMetrik(
                        "click news in landing page",
                        "news",
                        `news clicked in landing page ${item.id} ${
                          item?.title[i18n?.language]
                        }`,
                        `${item?.title[i18n?.language]}`
                      );
                      navigate(`/news/${item.id}`);
                    }}>
                    <img
                      src={item?.photoUrl}
                      alt="img"
                    />
                    <div className={cls.card_text}>
                      <div>
                        <div className={cls.title}>
                          {item?.title[i18n?.language]}
                        </div>

                        <div
                          className={cls.text}
                          dangerouslySetInnerHTML={{
                            __html: item?.description[i18n?.language].slice(
                              0,
                              item?.description[i18n?.language]?.indexOf(".") +
                                1
                            ),
                          }}
                        />
                      </div>

                      <div className={cls.date}> {item?.date} </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={cls.mobile_version}>
              <NewsSlider data={news} />
            </div>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default News;
