import { motion, useAnimation } from "framer-motion";
import cls from "./Body.module.scss";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
// import useTranslation from 'next-translate/useTranslation'
// import { useRouter } from 'next/router'
import enterIcon from "../../assets/icons/enter.svg";
import playButtonIcon from "../../assets/icons/play.svg";
import { Button, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import video from "../../assets/images/SOLIQ.mp4";

const Body = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { t, i18n } = useTranslation();

  // const router = useRouter()
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const container = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: 0.5,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div>
      <Container className={cls.container}>
        <div className={cls.wrapper} ref={ref}>
          <motion.div initial="hidden" exit="hidden" animate={controls} variants={container} className={`${cls.content}`}>
            <motion.h1
              variants={item}
              className={cls.title}
              transition={{
                delay: 0.4,
                duration: 0.5,
              }}
              exit="hidden"
            >
              {t("AutomatedElectronicDocumentManagementSystemSoliqservisUz")}
            </motion.h1>
            <a href={`https://new.soliqservis.uz/factura/login?lang=${i18n?.language}`} rel="noreferrer">
              <Button className={cls.enter_btn}>
                <img src={enterIcon} alt="enter_icon" />
                <div>{t("SignIn")}</div>
              </Button>
            </a>
            <Button className={cls.watch_video}>
              <img src={playButtonIcon} alt="play_icon" />
              {t("WatchTheVideo")}
            </Button>
          </motion.div>
          <div
            className={cls.image_box}
            style={{
              overflow: "visible",
            }}
          >
            <video className={cls.full_video} loop autoPlay muted>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Body;
