import { Container } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FaqService from "../../services/FaqService";
import styles from "./NewsSinglePage.module.scss";

function NewsSinglePage() {
  const { i18n } = useTranslation();
  const [data, setData] = useState();
  const id = useParams();

  const getNews = useCallback(() => {
    FaqService.getNewsById(id?.id).then((res) => {
      setData(res?.data);
    });
  }, [id.id]);

  useEffect(() => getNews(), [getNews]);

  return (
    <Container>
      <div className={styles.banner}>
        <img
          className={styles.image}
          src={data?.photoUrl}
          alt="img"
        />
        <div className={styles.title}>
          <h2>{data?.title[i18n?.language]}</h2>
          <p>{data?.titleDesc !== null && data?.titleDesc[i18n?.language]}</p>
        </div>
      </div>
      <div className={styles.image_container}>
        <img
          className={styles.image}
          src={data?.photoUrl}
          alt="img"
        />
      </div>
      <div className={styles.container}>
        <h2>{data?.title[i18n?.language]}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description[i18n?.language],
          }}
        />
      </div>
    </Container>
  );
}

export default NewsSinglePage;
