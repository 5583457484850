import React from "react";
// import { useRouter } from 'next/router'
import cls from "./NewsSlider.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import { Autoplay } from "swiper";

export default function NewsSlider({ data }) {
  // const { locale } = useRouter()

  return (
    <>
      <Swiper
        spaceBetween={40}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="NewsSwiper">
        {data?.slice(0, 3)?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={`${cls.card} ${cls.ibg}`}>
              <img
                className={cls.image}
                src={item?.photoUrl}
                alt="url photos"
              />
              <div className={cls.title}>{item?.title?.ru}</div>
              <div
                className={cls.desc}
                // dangerouslySetInnerHTML={{
                //   __html: item?.description[locale],
                // }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
