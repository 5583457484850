import { Container } from "@material-ui/core";
import { useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import cls from "./mobile.module.scss";
import checkedImg from "../../assets/images/checked.svg";
import mobileUser from "../../assets/images/mobile_user.png";

// import img3 from "../../../../public/images/news3.png"

const MobileApp = () => {
  const controls = useAnimation();
  const [inView] = useInView();
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      id="mobileApp"
      style={{ background: "#FFF" }}>
      <Container>
        <div
          className={cls.wrapper}
          id="about">
          <div className={cls.header}>
            <div className="header">{t("MobileOpportunities")}</div>
          </div>
          <div className={cls.body}>
            <div className={cls.cards}>
              <div className={cls.card}>
                <div className={cls.icon}>
                  <img
                    src={checkedImg}
                    alt="icon"
                  />
                </div>
                <div className={cls.text}>{t("createDocument")}</div>
              </div>
              <div className={cls.card}>
                <div className={cls.icon}>
                  <img
                    src={checkedImg}
                    alt="icon"
                  />
                </div>
                <div className={cls.text}>{t("seeDocument")}</div>
              </div>
              <div className={cls.card}>
                <div className={cls.icon}>
                  <img
                    src={checkedImg}
                    alt="icon"
                  />
                </div>
                <div className={cls.text}>{t("signDocument")}</div>
              </div>
              <div className={cls.card}>
                <div className={cls.icon}>
                  <img
                    src={checkedImg}
                    alt="icon"
                  />
                </div>
                <div className={cls.text}>{t("cancelDocument")}</div>
              </div>
            </div>
            <div className={cls.image}>
              <img
                src={mobileUser}
                alt="img"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MobileApp;
