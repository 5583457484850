import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import offertaPdf from "./test.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Offerta() {
  const [numPages, setNumPages] = useState(null);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
        <Document file={offertaPdf} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
              key={`page_${index + 1}`}
              className="pdf-page"
              pageNumber={index + 1}
              width={840}
            />
          ))}
        </Document>
      </div>
    </div>
  );
}

export default Offerta;
