import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import MainComponents from "./components";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import NewsMainPage from "./components/News/NewsMainPage";
import NewsSinglePage from "./components/News/NewsSinglePage";
import "./locales/i18n";
import Offerta from "./components/Offerta";
import GoogleAnalytics from "./utils/GoogleAnalytics";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <GoogleAnalytics />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback="loading">
              <MainComponents />
            </Suspense>
          }
        />
        <Route
          path="/news/:id"
          element={
            <Suspense fallback="loading">
              <NewsSinglePage />
            </Suspense>
          }
        />
        <Route
          path="/news"
          element={
            <Suspense fallback="loading">
              <NewsMainPage />
            </Suspense>
          }
        />
        <Route
          path="/offerta"
          element={
            <Suspense fallback="loading">
              <Offerta />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
