import { Container } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import FaqService from "../../services/FaqService";

import cls from "./News.module.scss";


const NewsMainPage = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [news, setNews] = useState([]);
  const navigate = useNavigate()
  
  const { t, i18n } = useTranslation();



  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 320,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
    ],
    customPaging: function (i) {
      return <div className={"dot"}></div>;
    },
  };

  const getNews = () => {
    FaqService.getNews()
      .then((res) => {
        setNews(res?.data?.news);
      })
  };

  useEffect(() => getNews(), []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const container = {
    hidden: { opacity: 1, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };
  const itemVariant = {
    hidden: { y: 20, opacity: 1 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div>
      <Container>
        <div className={cls.wrapper} ref={ref}>
          <div className={cls.news_header}>
            <motion.div
              className="header"
              variants={itemVariant}
              transition={{
                delay: 0.4,
                duration: 0.5,
              }}
              exit="hidden">
              {t("news")}
            </motion.div>
          </div>
          <motion.ul
            variants={container}
            initial="hidden"
            animate={controls}
            ref={ref}
            className={cls.cards_box}>
            <div className={cls.news_cards}>
              {news?.map((item) => (
                <motion.li
                  key={item?.id}
                  variants={itemVariant}
                  className={cls.card}>
                  <div onClick={() => navigate(`/news/${item.id}`)}>
                    <img src={item?.photoUrl} alt="img" />
                    <div className={cls.card_text}>
                      <div>
                        <div className={cls.title}>
                          {" "}
                          {item?.title[i18n?.language]}
                          {/* {i18n?.language === "uz"
                            ? item?.title.uz
                            : item?.title.ru} */}
                        </div>
                        <div
                          className={cls.text}
                          dangerouslySetInnerHTML={{
                            __html:   item?.title[i18n?.description]
                            // i18n?.language === "uz"
                            //     ? item?.description.uz
                            //     : item?.description.ru,
                          }}
                        />
                      </div>

                      <div className={cls.date}> {item.date} </div>
                    </div>
                  </div>
                </motion.li>
              ))}
            </div>

            <div className={cls.mobile_version}>
              <Slider {...settings}>
                {news?.map((item) => (
                  <motion.li
                    key={item?.id}
                    variants={itemVariant}
                    className={cls.card}>
                    <div onClick={() => navigate(`/news/${item.id}`)}>
                      <img src={item?.photoUrl} alt="img" />
                      <div className={cls.card_text}>
                        <div>
                          <div className={cls.title}> {item?.title.ru} </div>
                          <div
                            className={cls.text}
                            dangerouslySetInnerHTML={{
                              __html: item?.description.ru,
                            }}
                          />
                        </div>
                        <div className={cls.date}> {item?.date} </div>
                      </div>
                    </div>
                  </motion.li>
                ))}
              </Slider>
            </div>
          </motion.ul>
        </div>
      </Container>
    </div>
  );
};

export default NewsMainPage;
