import axios from "axios";
// import config from "../config/defaultSettings";
export const baseURL = 'https://new.soliqservis.uz/api/';

// axios new instance
const request = axios.create({
  // baseURL: config.baseURL, // ! Make CONSTANT
  baseURL: baseURL,
  headers: {
    // "platform-id": config?.CLIENT_PLATFORM_ID,
    "platform-id": "7d4a4c38-dd84-4902-b744-0488b80a4c01",
    // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});

export const requestAuth = axios.create({
  // baseURL: config?.baseAuthURL,
  baseURL: baseURL,
});

// request.interceptors.request.use(
//   (config) => {
//     if (localStorage.getItem("access_token")) {
//       config.headers.Authorization = `Bearer ${localStorage.getItem(
//         "access_token"
//       )}`;
//     }
//     return config;
//   },

//   (error) => errorHandler(error)
// );

// Error on connection
export const errorHandler = (error, hooks) => {
  if (error?.response) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      const refresh_token = localStorage.getItem("refresh_token");
      if (refresh_token) {
        requestAuth
          .put("/refresh", {
            refresh_token,
          })
          .then((res) => {
            const { access_token, refresh_token } = res?.data?.data?.token;
            if (access_token) {
              localStorage.setItem("access_token", access_token);
              localStorage.setItem("refresh_token", refresh_token);
            }
          })
          .catch(() => localStorage.setItem("access_token", ""));
      }
    }
  }

  return Promise.reject(error.response);
};

request.interceptors.response.use((response) => response.data, errorHandler);

export default request;
