import request from "../utils/request";

const FaqService = {
  getFacts: (params) => request({ method: "get", url: `/faq`, params }),
  getFactById: (id) => request({ method: "get", url: `/faq/${id}` }),
  // createFaq: (data) => request({ method: "post", url: "/faq", data }),
  // updateFaq: (data) => request({ method: "put", url: `/faq`, data }),
  // deletefaq: (id) => request({ method: "delete", url: `/faq/${id}` }),
  getTariffs: (params) => request({ method: "get", url: "/tariff", params}),
  getNews: (params) => request({ method: "get", url: "/news", params}),
  getNewsById: (id) => request({method: "get", url: `/news/${id}`}),
};

export default FaqService;
