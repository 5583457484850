import { Container } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import FaqService from "../../services/FaqService";
import cls from "./Fares.module.scss";
import checkboxIcon from "../../assets/images/checkbox_rounded.svg";

// import FaqService from "../../../services/FaqService";

// import { fetchData } from "../../../utils/fetchData";
import FaresSlider from "./FaresSlider/FaresSlider";

const Fares = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [tariffs, setTariffs] = useState([]);

  const links = {
    licenseLink:
      "https://new.soliqservis.uz/api/download/ss-files?link=documents/6552123569434aae2a503c5a-Litsenziya-2023.pdf",
    commercialOfferLinkRu:
      "https://new.soliqservis.uz/api/download/ss-documents?link=tijorat_taklifi_rus.pdf",
    commercialOfferLinkUz:
      "https://new.soliqservis.uz/api/download/ss-documents?link=tijorat_taklifi_uzb.pdf",
  };
  const { t, i18n } = useTranslation();

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const container = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: 0.5,
      },
    },
  };

  const fetchTariffs = () => {
    FaqService.getTariffs().then((res) => {
      setTariffs(res?.data?.tariffs);
    });
  };

  useEffect(() => {
    fetchTariffs();
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      id="rates"
      style={{ background: "#F3F6FA" }}>
      <Container>
        <div
          className={cls.wrapper}
          ref={ref}
          id="about">
          <motion.div
            initial="hidden"
            exit="hidden"
            animate={controls}
            variants={container}>
            <motion.div
              className="header"
              variants={item}
              transition={{
                delay: 0.4,
                duration: 0.5,
              }}
              exit="hidden">
              {t("Tariffs")}
            </motion.div>
            <div className={cls.download_buttons}>
              <a
                href={
                  i18n?.language === "ru"
                    ? links.commercialOfferLinkRu
                    : links.commercialOfferLinkUz
                }
                target="_blank"
                rel="noreferrer"
                className={cls.download_btn}>
                {t("DownloadCommercialOffer")}
              </a>
              <a
                href={links.licenseLink}
                target="_blank"
                rel="noreferrer"
                className={cls.download_btn}>
                <div className={cls.download_btn}>{t("DownloadLicense")}</div>
              </a>
            </div>

            <div className={cls.ratesCard}>
              {tariffs
                ?.slice(0, 4)
                ?.reverse()
                .map((item, index) => (
                  <div
                    key={item.id}
                    className={cls.card}>
                    {item.extra ? (
                      <div className={cls.suggestion}>{item.extra}</div>
                    ) : (
                      ""
                    )}
                    <div className={cls.suggestion}>
                      {index === 0 ? t("in_sale") : t("suggestion")}
                    </div>

                    <div className={cls.card_status}>
                      {" "}
                      {item.name[i18n?.language]}{" "}
                    </div>
                    <div
                      className={cls.under_title}
                      dangerouslySetInnerHTML={{
                        __html: item?.description[i18n?.language]?.replace(
                          /\n/g,
                          "<br />"
                        ),
                      }}></div>

                    {item?.attributes?.map((strings, index) => (
                      <div
                        className={cls.card_items}
                        key={index}>
                        <img
                          src={checkboxIcon}
                          className={cls.checkbox}
                          alt="checkbox"
                          width={20}
                          height={20}
                        />
                        <div>{strings.attribute_name[i18n?.language]}</div>
                      </div>
                    ))}
                    {item?.disabled_text?.map((dis, index) => (
                      <div
                        className={cls.card_items}
                        key={index}>
                        <img
                          src={item.icon_disabled}
                          className={cls.checkbox}
                          alt="checkbox"
                          width={20}
                          height={20}
                        />
                        <div> {dis} </div>
                      </div>
                    ))}
                  </div>
                ))}
            </div>

            <div className={cls.ratesCard_mobile_version}>
              <FaresSlider data={tariffs} />
            </div>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default Fares;
