import React from "react";

// import dynamic from "next/dynamic";
// import required modules
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import cls from "./ClientsSlider.module.scss";

function ClientsSlider( {data} ) {  
  return (
    <div className={cls.swiper}>
      <Swiper
        spaceBetween={20}
        grabCursor={true}
        speed={2000}
        loop={true}
        freeMode={true}
        freeModeMomentum={false}
        autoplay={{
          delay: 500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          350: {
            slidesPerView: 1.5,
          },
          556: {
            slidesPerView: 3,
          },
          767: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 5,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]}
        className="ClientsSwiper"
      >
        {data?.map((item, index) => (
          <SwiperSlide key={index}>
            <div key={item?.id} className={`${cls.card} ${cls.ibg}`}>
              <img src={item?.url} alt="logo" />
            </div>
          </SwiperSlide>
          
        ))}

        {/* {data?.map((item) =>  <img src={item?.url} alt="icon" /> )} */}
      </Swiper>
    </div>
  );
}

export default ClientsSlider

// export default dynamic(() => Promise.resolve(ClientsSlider), { ssr: false });

// export default React.lazy(() => < ClientsSlider />)
