import { Box, Container, FormControl, MenuItem, Select } from "@material-ui/core";
// import { useRouter } from "next/router";
import { KeyboardArrowDown } from "@material-ui/icons";
// import Link from "next/link";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import BurgerMenu from "../BurgerMenu";
import cls from "./Header.module.scss";
import { useTranslation } from "react-i18next";

const Header = () => {
  // const { locale } = useRouter();
  const [language, setLanguage] = useState(0);
  const [langOpen, setLangOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const router = location?.pathname;

  const arrowDown = {
    cursor: "pointer",
    width: "30px",
    marginRight: "10px",
  };
  const arrowUp = {
    cursor: "pointer",
    width: "30px",
    marginRight: "10px",
    transform: "rotate(180deg)",
  };

  useEffect(() => {
    i18n.changeLanguage(language === 0 ? "uz" : language === 1 ? "ru" : "oz");
  }, [language, i18n]);

  const burgerData = [
    {
      id: 1,
      name: t("news"),
      href: "/#news",
    },
    // {
    //   id: 2,
    //   name: t("HowItWorks"),
    //   href: "/#howitworks",
    // },
    {
      id: 3,
      name: t("Capabilities"),
      href: "/#opportunites",
    },
    {
      id: 4,
      name: t("AboutTheSystem"),
      href: "/system",
    },
    {
      id: 5,
      name: t("globalOffertaText"),
      href: "https://new.soliqservis.uz/factura/publicOffer/2",
    },
  ];

  return (
    <div
      style={{ position: "relative" }}
      className={`${router === "/" ? cls.main_container : cls.container} ${router.substring(0, 6) === "/news/" && cls.news_active}`}
    >
      <Container className={cls.mobile_container}>
        <Box className={cls.header_menu}>
          <div className={cls.logo}>
            <a href={"/"}>
              <img style={{ cursor: "pointer" }} src={logo} width="70px" height="70px" alt="logo" />
            </a>
          </div>
          <div className={cls.headerMenu_items}>
            <a href="/#news">{t("news")}</a>
            <a href="/#rates">{t("Tariffs")}</a>
            {/* <a href="/#howitworks" >{t("HowItWorks")}</a> */}
            <a href="/#opportunites">{t("Capabilities")}</a>
            <a href="/offerta">{t("offerta")}</a>
            {/* <a href="https://new.soliqservis.uz/factura/publicOffer/2" style={{ width: "250px", textAlign: "center" }}>
              {" "}
              {t("globalOffertaText")}{" "}
            </a> */}
          </div>

          <div className={cls.contact_lang_btns}>
            <div className={cls.contact}>
              <a href="tel:+998712023282">71 202-3282</a> <span>/ 1198</span>
            </div>
            <div className={cls.select_language}>
              <FormControl style={{ height: "100%" }}>
                <Select
                  open={langOpen}
                  value={language}
                  onClick={() => setLangOpen(!langOpen)}
                  onChange={(e) => setLanguage(e.target.value)}
                  className={cls.language}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={{ paddingLeft: "11px" }}
                  IconComponent={() => <KeyboardArrowDown style={!langOpen ? arrowDown : arrowUp} onClick={() => setLangOpen(!langOpen)} />}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={0}>
                    {/* <a href={`${router}`}  locale="ru" style={{textDecoration: 'none', color: 'black'}}> */}
                    {`O'zbekcha`}
                    {/* </a> */}
                  </MenuItem>
                  <MenuItem value={1}>
                    {/* <a href={`${router}`} locale="uz" style={{textDecoration: 'none', color: 'black'}}> */}
                    Русский
                    {/* </a> */}
                  </MenuItem>
                  <MenuItem value={2}>
                    {/* <a href={`${router}`} locale="oz" style={{textDecoration: 'none', color: 'black'}}> */}
                    Ўзбекча
                    {/* </a> */}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <BurgerMenu data={burgerData} className={cls.burger} />
        </Box>
      </Container>
      {/* {router.route === "/" ? <Body /> : ""} */}
    </div>
  );
};

export default Header;
