import { Container } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import cls from "./Opportunites.module.scss";
import img1 from "../../assets/images/opp1.svg";
import img2 from "../../assets/images/opp2.svg";
import img3 from "../../assets/images/opp3.svg";
import img4 from "../../assets/images/opp4.svg";

import { useTranslation } from "react-i18next";

const Opportunites = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      title: t("documentExchange"),
      text: t("documentExchangeDesc"),
      icons: img1,
    },
    {
      id: 2,
      title: t("controlBranch"),
      text: t("controlBranchDesc"),
      icons: img2,
    },
    {
      id: 3,
      title: t("controlComisser"),
      text: t("controlComisserDesc"),
      icons: img3,
    },
    {
      id: 4,
      title: t("mxikControl"),
      text: t("mxikControlInfo"),
      icons: img4,
    },
  ];

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const container = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div id="opportunites">
      <Container>
        <div
          className={cls.wrapper}
          ref={ref}
          id="about">
          <motion.div
            initial="hidden"
            exit="hidden"
            animate={controls}
            variants={container}>
            <motion.div
              className="header"
              variants={item}
              transition={{
                delay: 0.4,
                duration: 0.5,
              }}
              exit="hidden">
              {t("Capabilities")}
            </motion.div>

            <div className={cls.opportunity_card}>
              {data.map((item) => (
                <div
                  key={item.id}
                  className={cls.card}>
                  <div className={cls.card_img}>
                    <img
                      src={item.icons}
                      alt="icons"
                    />
                  </div>
                  <div>
                    <div className={cls.card_title}> {item.title} </div>
                    <div className={cls.card_text}> {item.text} </div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default Opportunites;
