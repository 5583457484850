import { Container } from "@material-ui/core";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import cls from "./stats.module.scss";
import stats_img1 from "../../assets/images/stat1.svg";
import stats_img2 from "../../assets/images/stat2.svg";
import stats_img3 from "../../assets/images/stat3.svg";
import stats_img4 from "../../assets/images/stat4.svg";

const Statistics = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { t } = useTranslation();

  const data = [
    {
      id: 0,
      icon: stats_img1,
      amount: "680 000+",
      text: t("acceptedUsers"),
    },
    {
      id: 1,
      icon: stats_img2,
      amount: "40+",
      text: t("servicePersonnel"),
    },
    {
      id: 2,
      icon: stats_img3,
      amount: "36 000 000+",
      text: t("allDocuments"),
    },
    {
      id: 3,
      icon: stats_img4,
      amount: "20 000+",
      text: t("acceptCallCenter"),
    },
  ];

  const container = {
    hidden: { opacity: 1, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariant = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      id="statistics"
      style={{ background: "#F3F6FA" }}>
      <Container>
        <div
          className={cls.wrapper}
          ref={ref}
          id="about">
          <motion.div
            initial="hidden"
            exit="hidden"
            animate={controls}
            variants={container}>
            <motion.div
              className="header"
              variants={itemVariant}
              transition={{
                delay: 0.4,
                duration: 0.5,
              }}
              exit="hidden"
              style={{ paddingBottom: "24px" }}>
              {t("Statistics")}
            </motion.div>
            <motion.ul
              variants={container}
              initial="hidden"
              animate={controls}
              ref={ref}
              style={{ padding: "0px", margin: "0px" }}>
              <div className={cls.opportunity_card}>
                {data.map((item) => (
                  <div
                    key={item.id}
                    className={cls.card}>
                    <div className={cls.card_img}>
                      <img
                        src={item.icon}
                        alt="icons"
                      />
                    </div>
                    <div className={cls.body}>
                      <div className={cls.card_amount}> {item.amount} </div>
                      <div className={cls.card_text}> {item.text} </div>
                    </div>
                  </div>
                ))}
              </div>
            </motion.ul>
          </motion.div>
        </div>
      </Container>
    </div>
  );
};

export default Statistics;
