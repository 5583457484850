import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import googlePlay from "../../assets/images/google_play.svg";
import appStore from "../../assets/images/appstore.svg";
import messageIcon from "../../assets/icons/message.svg";
import callIcon from "../../assets/icons/call.svg";
import telegramIcon from "../../assets/icons/telegram.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import facebookIcon from "../../assets/icons/facebook.svg";
import telegram_roundIcon from "../../assets/icons/telegram_round.svg";

// import Link from "next/link";
import { Map, YMaps, Placemark } from "react-yandex-maps";
import cls from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div style={{ background: "#F5F7F9" }}>
      <Container>
        <div className={cls.wrapper}>
          <div className={cls.left_side}>
            <div className={cls.links}>
              <ul>
                <li>
                  <a href="/#news">{t("news")}</a>
                </li>
                <li>
                  <a href="/#howitworks">{t("HowItWorks?")}</a>
                </li>
                <li>
                  <a href="/#opportunites">{t("Capabilities")}</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://new.soliqservis.uz/factura/publicOffer/1">
                    {t("PublicOffer")}
                  </a>
                </li>
                <li>
                  <a href="/#rates">{t("Tariffs")}</a>
                </li>
              </ul>
            </div>
            <div className={cls.mobile_apps}>
              <div className={cls.header}>{t("OurMobileApplication")}</div>
              <div className={cls.apps}>
                <img
                  src={googlePlay}
                  alt="google_play"
                />
                <img
                  src={appStore}
                  alt="appstore"
                />
              </div>
            </div>
          </div>

          <div className={cls.right_side}>
            <div className={cls.contacts}>
              <div>
                <img
                  src={messageIcon}
                  alt="icon"
                />
                <span>servis@soliq.uz</span>
              </div>
              <div>
                <img
                  src={callIcon}
                  alt="icon"
                />
                <span>
                  <a href="tel:+998712023282">(+998 71) 202 32 82,</a>{" "}
                  {t("ShortNumber1198")}
                </span>
              </div>
              <div>
                <img
                  src={messageIcon}
                  alt="message"
                />
                <span>{t("Requisites")}</span>
              </div>
              <div
                style={{
                  width: "264px",
                  color: "#6D7885",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("footerText"),
                }}
              />
            </div>
            <div className={cls.map}>
              <div>
                <img
                  src={telegramIcon}
                  alt="telegram soliq"
                />
                <span>{t("Address")}</span>
              </div>
              <div className={cls.maps}>
                <YMaps>
                  <Map
                    defaultState={{
                      center: [41.295692, 69.218247],
                      zoom: 12,
                    }}>
                    <Placemark geometry={[41.295692, 69.218247]} />
                  </Map>
                </YMaps>
              </div>
            </div>
          </div>
        </div>
        <div className={cls.footer_bottom}>
          <div>© soliqservis.uz 2024 All rights reserved</div>
          <div>
            <img
              src={instagramIcon}
              alt="insta"
            />
            <img
              src={facebookIcon}
              alt="facebook"
            />
            <img
              src={telegram_roundIcon}
              alt="telegram"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
